import {defineStore} from 'pinia';
import {persistedState} from "../.nuxt/imports";


export const useTableFilterStorage = defineStore({
  id: 'tableFilterStorage',
  state: () => {
    return {
      newOrExisting: [], //available values: "Bestand", "Neueingang"
      stati: [],
      recruiter: [],
      tags: [],
      filter: '',
      pageCount: 25,
    }
  }, persist: {
    storage: persistedState.localStorage,
  },
  actions: {
    reset() {
      this.newOrExisting = [];
      this.stati = [];
      this.recruiter = [];
      this.tags = [];
      this.filter = '';
      this.pageCount = 25;
    },
    isValuePresent() {
      return this.newOrExisting.length > 0 || this.stati.length > 0 || this.recruiter.length > 0 || this.tags.length > 0 || this.filter.length > 0
    }
  },
})
